<template>
  <TosListVuetify serviceName="registry" entityName="countries" :headers="headers" :validateFunction="validate">
    <template #item.actions="slotProps">
      <v-btn icon title="Modifica" @click="$emit('tableOpenItem', slotProps.data.codice)">
        <v-icon color="primary" medium>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon title="Elimina" @click="onDelete(slotProps.data)">
        <v-icon color="primary" medium>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field :label="tLabel('Codice')" v-model="slotProps.data.codice" />
          </v-col>
          <v-col cols="4">
            <v-text-field :label="tLabel('Descrizione')" v-model="slotProps.data.descrizioneLike" />
          </v-col>
          <v-col cols="4">
            <v-text-field :label="tLabel('Area')" v-model="slotProps.data.areaLike" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="2">
            <v-text-field :label="tLabelRequired('codice')" v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
          </v-col>
          <v-col cols="5">
            <v-text-field :label="tLabelRequired('descrizione')" v-model="slotProps.data.descrizione" :rules="[rules.required]" :error-messages="slotProps.errors.descrizione" />
          </v-col>
          <v-col cols="5">
            <v-text-field :label="tLabel('area')" v-model="slotProps.data.area"  :error-messages="slotProps.errors.area" />
          </v-col>
        </v-row>
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "@/components/anagrafiche/TosListVuetify";
import FormatsMixins from "@/mixins/FormatsMixins";
export default {
  name: "Countries",
  data() {
    return {
      headers: [
        { value: "codice", text: "Codice" },
        { value: "descrizione", text: "Descrizione" },
        { value: "area", text: "area" },
      ],
    };
  },
  service: null,
  created() {},
  methods: {
    validate(item, errors) {
      if (item.codice.length != 2) errors.add("codice", "Deve essere 2 caratteri");
      item.codice=item.codice.toUpperCase();
    },
    onDelete(item){
      item.id=item.codice;
      this.$emit('confirm', 'Confermi la cancellazione ? ', 'deleteItem', item.codice)
    }
  },
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>
